<template>
  <div class="bg-gray">
    <div class="container-fluid">
      <div class="user-card">
        <h1 style="float: left;">Summary</h1>
        <table class="table table-striped">
          <thead>
          <tr>
            <th>Product</th>
            <th>Month</th>
            <th>Total</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in summary" :key="item.productId">
            <td>
              <span style="float: left;">{{ item.productName}}</span>
            </td>
            <td>
              <span style="float: left;">{{ item.month}}</span>
            </td>
            <td>
              <span style="float: left;">{{ item.totalPrice}}</span>
            </td>
          </tr>
          </tbody>
        </table>

        <p style="float: left; font-weight: bold; margin-bottom:10px;">Total: {{ sumTotalPrice }}</p>
        <br><br>
        <h1 style="margin-top: 10px; float: left">Subscriptions</h1>  

        <table class="table table-striped">
          <thead>
            <tr>
              <th>Product</th>
              <th>Status</th>
              <th>Price</th>
              <th>Expires Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="sub in subscriptions" :key="sub.id">
              <td>
                <span style="float: left;">{{ sub.productName}}</span>
              </td>
              <td>
                <span style="float: left;">{{ sub.status}}</span>
              </td>
              <td>
                <span style="float: left;">{{ sub.price}}</span>
              </td>
              <td>
                <span style="float: left;">{{ sub.expires}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { StoreActions } from "./store/actions";
import StoreIndex from "./store/_StoreIndex";
import {StoreState as state} from './store/state'

export default {
  name: "Subscriptions",
  components: {
  },
  data() {
    return {
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.dispatch(StoreActions.getSubscriptions);
    },
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    }
  },
  computed: {
    ...mapState(StoreIndex.path,[
      state.subscriptions,
        state.summary,
        state.sumTotalPrice
    ])
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background: #46c3f0;
  color: white;
}
.btn-primary {
  background: #46c3f0 !important;
  border-color: #46c3f0 !important;
}

a {
  color: black;
  font-weight: bold;
}

.btn-secondary {
  background: lightgray;
  border-color: lightgray;
}
</style>