import APIConnection from '@/api/APIConnection';
export default {
    stats(){
        var url = `${APIConnection.v2Url}/roomie/stats`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    getUser(id) {
        var url = `${APIConnection.v2Url}/roomie/user/${id}`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    getMessages(conversationId) {
        var url = `${APIConnection.v2Url}/roomie/conversations/${conversationId}/messages`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    searchMessages(filters) {
        var url = `${APIConnection.v2Url}/roomie/messages/search`;
        return APIConnection.post(url, filters, APIConnection.v2Interceptor);
    },
    suspend(id) {
        var url = `${APIConnection.v2Url}/roomie/user/${id}/suspend`;
        return APIConnection.post(url,{},APIConnection.v2Interceptor);
    },
    getReportedUsers() {
        var url = `${APIConnection.v2Url}/roomie/users/reported`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },    
    getRecentUsers() {
        var url = `${APIConnection.v2Url}/roomie/users/recent`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    searchHousing(data) {
        var url = `${APIConnection.v2Url}/roomie/housing/search`;
        return APIConnection.post(url, data, APIConnection.v2Interceptor);
    },
    searchUsers(data) {
        var url = `${APIConnection.v2Url}/roomie/users/search`;
        return APIConnection.post(url, data, APIConnection.v2Interceptor);
    },
    getHousing(id) {
        var url = `${APIConnection.v2Url}/roomie/housing/${id}`;
        return APIConnection.get(url, APIConnection.v2Interceptor);
    },
    updateHousingApproved(data) {
        var url = `${APIConnection.v2Url}/roomie/housing/approved`;
        return APIConnection.post(url, data, APIConnection.v2Interceptor);
    },
    charts(limit) {
        var url = `${APIConnection.v2Url}/roomie/charts?limit=${limit}`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    popularUniversities() {
        var url = `${APIConnection.v2Url}/roomie/universities/popular`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    popularLocations() {
        var url = `${APIConnection.v2Url}/roomie/locations/popular`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    pendingSentiment() {
        var url = `${APIConnection.v2Url}/roomie/sentiment/pending`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    updateSentiment(id, sentiment) {
        var url = `${APIConnection.v2Url}/roomie/sentiment/${id}`;
        return APIConnection.post(url, {sentiment: sentiment}, APIConnection.v2Interceptor);
    },
    pendingHousingSentiment() {
        var url = `${APIConnection.v2Url}/roomie/housing/sentiment/pending`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    updateHousingSentiment(id, sentiment) {
        var url = `${APIConnection.v2Url}/roomie/housing/sentiment/${id}`;
        return APIConnection.post(url, {sentiment: sentiment}, APIConnection.v2Interceptor);
    },
    pendingVerify() {
        var url = `${APIConnection.v2Url}/roomie/verification/pending`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    reviewVerify(id) {
        var url = `${APIConnection.v2Url}/roomie/verification/${id}/reviewed`;
        return APIConnection.post(url, {}, APIConnection.v2Interceptor);
    },
    rejectVerify(id, data) {
        var url = `${APIConnection.v2Url}/roomie/verification/${id}/reject`;
        return APIConnection.post(url, data, APIConnection.v2Interceptor);
    },
    pendingFlaggedMessages(suspended) {
        var url = `${APIConnection.v2Url}/roomie/messages/flagged?suspended=${suspended}`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    reviewFlaggedMessage(id, sentiment) {
        var url = `${APIConnection.v2Url}/roomie/messages/flagged/${id}/review`;
        return APIConnection.post(url, {sentiment: sentiment}, APIConnection.v2Interceptor);
    },
    bulkReviewVerify(ids) {
        var url = `${APIConnection.v2Url}/roomie/verification/reviewed/bulk`;
        return APIConnection.post(url, {ids: ids}, APIConnection.v2Interceptor);

    },
    needsVerify(id) {
        var url = `${APIConnection.v2Url}/roomie/verification/${id}/pending`;
        return APIConnection.post(url, {}, APIConnection.v2Interceptor);

    },
    timeout(id) {
        var url = `${APIConnection.v2Url}/roomie/users/${id}/timeout`;
        return APIConnection.post(url, {}, APIConnection.v2Interceptor);
    },
    grantPro(id) {
        var url = `${APIConnection.v2Url}/roomie/users/${id}/pro`;
        return APIConnection.patch(url, {}, APIConnection.v2Interceptor);
    },
    pendingBioSentiment() {
        var url = `${APIConnection.v2Url}/roomie/users/sentiment/pending`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    updateBioSentiment(id, sentiment) {
        var url = `${APIConnection.v2Url}/roomie/users/sentiment/${id}`;
        return APIConnection.post(url, {sentiment: sentiment}, APIConnection.v2Interceptor);
    },
    getSubscriptions() {
        var url = `${APIConnection.v2Url}/roomie/subscriptions`;
        return APIConnection.get(url, APIConnection.v2Interceptor);
    },
    pendingFlaggedProfilePictures() {
        var url = `${APIConnection.v2Url}/roomie/profilePictures/flagged`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    reviewFlaggedProfilePicture(id) {
        var url = `${APIConnection.v2Url}/roomie/profilePictures/flagged/${id}/review`;
        return APIConnection.post(url, {}, APIConnection.v2Interceptor);
    },
    removeFlaggedProfilePicture(id) {
        var url = `${APIConnection.v2Url}/roomie/profilePictures/flagged/${id}/remove`;
        return APIConnection.post(url, {}, APIConnection.v2Interceptor);
    },
    pendingFlaggedMessageMedia() {
        var url = `${APIConnection.v2Url}/roomie/messages/media/flagged`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    reviewFlaggedMessageMedia(id) {
        var url = `${APIConnection.v2Url}/roomie/messages/media/flagged/${id}/review`;
        return APIConnection.post(url, {}, APIConnection.v2Interceptor);
    },
    getFlaggedHousing() {
        var url = `${APIConnection.v2Url}/roomie/housing/flagged/pending`;
        return APIConnection.get(url, APIConnection.v2Interceptor);
    },
    reviewFlaggedHousing(data) {
        var url = `${APIConnection.v2Url}/roomie/housing/flagged/${data.id}/review`;
        return APIConnection.post(url, data, APIConnection.v2Interceptor);
    },
    pendingFlaggedGroupMessages(suspended) {
        var url = `${APIConnection.v2Url}/roomie/messages/group/flagged?suspended=${suspended}`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    reviewFlaggedGroupMessage(id, sentiment) {
        var url = `${APIConnection.v2Url}/roomie/messages/group/flagged/${id}/review`;
        return APIConnection.post(url, {sentiment: sentiment}, APIConnection.v2Interceptor);
    },
}