<template>
  <div class="bg-gray">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <button @click="bulkReview('Safe')" class="btn btn-primary" style="margin-right: 10px;">All Safe</button>

          <button @click="bulkReview('Scam')" class="btn btn-danger">All Scam</button>
        </div>
      </div>
      <div class="user-card">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Sender</th>
              <th>Sender ID</th>
              <th>Suspended</th>
              <th>Message</th>
              <th>TS</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="message in messages" :key="message.id">
              <td class="col-sm-2">
                <router-link :to="`/roomie/user/${message.senderId}`">
                  {{message.name}}
                  <br>
                </router-link>
              </td>
              <td>
                <span style="float: left;">{{ message.senderId }}</span>
              </td>
              <td>
                <span style="float: left;">{{ message.suspended === 1 ? "Yes" : "No" }}</span>
              </td>
              <td style="max-width: 200px; word-wrap: break-word;">
                <img
                  class="user-img"
                  v-if="message.signedUrl"
                  height="200px"
                  :src="message.signedUrl"
                />
                <span v-else>
                {{ messageString(message.message) }}
                </span>
              </td>
              <td>
                <span style="float: left;">{{ message.timestamp}}</span>
              </td>
              <td>
                <button class="btn btn-danger" style="margin-bottom: 5px;" @click="review(message.id, 'Scam')">Scam</button>
                <br>
                <button class="btn btn-warning" style="margin-bottom: 5px;" @click="review(message.id, 'Suspicious')">Suspicious</button>
                <br>
                <button class="btn btn-primary" @click="review(message.id, 'Safe')">Safe</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <button @click="bulkReview('Safe')" class="btn btn-primary" style="margin-right: 10px;">All Safe</button>

          <button @click="bulkReview('Scam')" class="btn btn-danger">All Scam</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { StoreActions } from "./store/actions";
import StoreIndex from "./store/_StoreIndex";
import {StoreState as state} from './store/state'

export default {
  name: "FlaggedMessages",
  components: {
  },
  data() {
    return {
      suspended: 0
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.dispatch(StoreActions.getMessages, this.suspended);
    },
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    },
    review(id, sentiment) {
      this.dispatch(StoreActions.markReviewed, {id: id, sentiment: sentiment, callback: () => {
        this.load();
      }});
    },
    messageString(msg) {
      return String.raw`${msg}`
    },
    bulkReview(sentiment) {
      this.messages.forEach(it => {
        this.review(it.id, sentiment);
      })
    }
  },
  computed: {
    ...mapState(StoreIndex.path,[
      state.messages,
      state.page,
      state.search
    ]),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background: #46c3f0;
  color: white;
}
.btn-primary {
  background: #46c3f0 !important;
  border-color: #46c3f0 !important;
}

a {
  color: black;
  font-weight: bold;
}

.btn-secondary {
  background: lightgray;
  border-color: lightgray;
}

@media (max-width: 700px) {
  .mobile-hide {
    display: none;
  }
}

@media (min-width: 701px) {
  .large-hide {
    display: none;
  }
}

.user-card {
  background: white;
  margin: 20px;
  border-radius: 10px;
}

.user-img {
  border-radius: 10px;
  margin: 10px;
}

label {
  color: gray;
  font-weight: bold;
}

.user-photo {
  width:100px;
  height:100px;
  border-radius: 50px;
  object-fit: cover;
}

@media (max-width:767px) {
  .user-photo {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
}
</style>