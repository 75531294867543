<template>
  <div class="uiv">
    <navbar>
      <a class="navbar-brand" slot="brand" href="#">
      </a>
      <template slot="collapse">
        <navbar-nav>

            <li>
              <router-link role="button" :to="'/'">Home</router-link>
            </li>


            <dropdown tag="li">
              <a class="dropdown-toggle" role="button">Roomie <span class="caret"></span></a>
              <template slot="dropdown">
                <li>
                  <router-link role="button" :to="'/roomie/bioSentiment'">
                    Bio Sentiment
                  </router-link>
                </li>
                <li>
                  <router-link role="button" :to="'/roomie/charts'">
                    Charts
                  </router-link>
                </li>
                <li>
                  <router-link role="button" :to="'/roomie/housing'">
                    Housing
                  </router-link>
                </li>
                <li>
                  <router-link role="button" :to="'/roomie/housing/sentiment'">
                    Housing Sentiment
                  </router-link>
                </li>
                <li>
                  <router-link role="button" :to="'/roomie/locations'">
                    Locations
                  </router-link>
                </li>
                <li>
                  <router-link role="button" :to="'/roomie/messages/group/flagged'">
                    Flagged Group Messages
                  </router-link>
                </li>
                <li>
                  <router-link role="button" :to="'/roomie/housing/flagged'">
                    Flagged Housing
                  </router-link>
                </li>
                <li>
                  <router-link role="button" :to="'/roomie/flaggedProfilePictures'">
                    Flagged Profile Pictures
                  </router-link>
                </li>
                <li>
                  <router-link role="button" :to="'/roomie/messages/flagged'">
                    Flagged Messages
                  </router-link>
                </li>
                <li>
                  <router-link role="button" :to="'/roomie/flaggedMessageMedia'">
                    Flagged Message Media
                  </router-link>
                </li>
                <li>
                  <router-link role="button" :to="'/roomie/messages'">
                    Messages
                  </router-link>
                </li>
                <li>
                  <router-link role="button" :to="'/roomie/users/recent'">
                    Recent
                  </router-link>
                </li>
                <li>
                  <router-link role="button" :to="'/roomie/users/reported'">
                    Reported Users
                  </router-link>
                </li>
                <li>
                  <router-link role="button" :to="'/roomie/users/search'">
                    Search
                  </router-link>
                </li>
                <li>
                  <router-link role="button" :to="'/roomie/sentiment'">
                    Sentiment
                  </router-link>
                </li>
                <li>
                  <router-link role="button" :to="'/roomie/subscriptions'">
                    Subscriptions
                  </router-link>
                </li>
                <li>
                  <router-link role="button" :to="'/roomie/universities'">
                    Universities
                  </router-link>
                </li>
                <li>
                  <router-link role="button" :to="'/roomie/verify'">
                    Verify
                  </router-link>
                </li>
              </template>
            </dropdown>

            <dropdown tag="li">
              <a class="dropdown-toggle" role="button">Whiteboard <span class="caret"></span></a>
              <template slot="dropdown">
                <li>
                  <router-link role="button" :to="'/whiteboard/charts'">
                    Charts
                  </router-link>
                </li>
                <li>
                  <router-link role="button" :to="'/whiteboard/messages'">
                    Messages
                  </router-link>
                </li>
                <li>
                  <router-link role="button" :to="'/whiteboard/users/location'">
                    Map
                  </router-link>
                </li>
              </template>
            </dropdown>

          <li>
            <a @click="logout" href="#">Logout</a>
          </li>
        </navbar-nav>
      </template>
    </navbar>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    logout() {
      console.log("log out")
      this.$destroySession()
      this.$router.push('/login')
    }
  },
  computed: {
    routes() {
      return [
        {
          name: "Home",
          link: "/home"
        },
        {
          name: 'Whiteboard',
          children: [
            {
              name: "Whiteboard Charts",
              link: "/whiteboard/charts"
            },
            {
              name: "Whiteboard Images",
              link: "/whiteboard/messages"
            }
          ]
        }
      ];
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar-default {
  height: 75px;
}

li {
  font-size: 12pt;
  padding-top: 10px;
}

@media(max-width:767px) {
  .navbar-nav {
    background:#efefef;
  }

  .navbar {
    z-index: 2;
  }
}
</style>
