// action constants
import Vue from 'vue'
import WhiteboardRepository from "@/api/WhiteboardRepository";
import { StoreMutations } from './mutations';
import RoomieRepository from '../../../api/RoomieRepository';

export const StoreActions = {
    whiteboardStats:'whiteboardStats',
    roomieStats: 'roomieStats',
};

export const actions = {
    whiteboardStats(context) {
        WhiteboardRepository.stats()
        .then((data) => {
            let stats = [
                {
                    title:"Users",
                    value:data.totalUsers
                },
                {
                    title:"Today Users",
                    value:data.todayUsers
                },
                {
                    title:"Today Messages",
                    value:data.todayMessages
                },
                {
                    title:"Active Today",
                    value:data.activeToday
                },
                {
                    title:"Active This Month",
                    value:data.activeThisMonth
                },
                {
                    title:"Active Last Month",
                    value:data.activeLastMonth
                }
            ]
            context.commit(StoreMutations.SET_WHITEBOARD_STATS,stats);        
        })
        .catch((err) => {
            console.log(err)
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    roomieStats(context) {
        RoomieRepository.stats()
        .then((data) => {
            let stats = [
                {
                    title:"Users",
                    value:data.numUsers
                },
                {
                    title:"Today Users",
                    value:data.todayUsers
                },
                {
                    title:"Active Today",
                    value:data.activeToday
                },
                {
                    title:"Active Yesterday",
                    value:data.activeYesterday
                },
                {
                    title:"Active This Month",
                    value:data.activeThisMonth
                },
                {
                    title:"Active Last Month",
                    value:data.activeLastMonth
                },
                {
                    title:"Photo Scan Pending",
                    value:data.userPhotoNeedsScanned
                },
                {
                    title:"Compress Pending",
                    value:data.userPhotoNeedsCompressed
                },
                {
                    title:"Photo Delete Pending",
                    value:data.userPhotoNeedsDeleted
                },
                {
                    title:"Media Scan Pending",
                    value:data.messageMediaNeedsScanned
                },
                {
                    title:"Flagged User Photos",
                    value:data.flaggedUserPhotoCount
                },
                {
                    title:"Flagged Message Media",
                    value:data.flaggedMessageMediaCount
                },
                {
                    title:"Flagged Messages",
                    value:data.flaggedMessageCount
                },
                {
                    title:"Flagged Housing",
                    value:data.flaggedHousingCount
                },
                {
                    title:"Flagged Group Messages",
                    value:data.flaggedGroupMessageCount
                },

                {
                    title:"Pending Verify",
                    value:data.pendingVerify
                },

                {
                    title:"Pending Pushes",
                    value:data.pendingPushCount
                },

                {
                    title:"Pending IP Addresses",
                    value:data.pendingIpLookupCount
                },

            ]
            context.commit(StoreMutations.SET_ROOMIE_STATS,stats);        
        })
        .catch((err) => {
            console.log(err)
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    }
};
