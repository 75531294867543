// action constants
import Vue from 'vue'
import { StoreMutations } from './mutations';
import RoomieRepository from '../../../../api/RoomieRepository';

export const StoreActions = {
    getFlagged:'getFlagged',
    review: 'review',
    clearState:'clearState'
};

export const actions = {
    [StoreActions.getFlagged](context, id) {
        RoomieRepository.getFlaggedHousing(id)
        .then((data) => {
            context.commit(StoreMutations.SET_LISTING, data.item)
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    [StoreActions.review](context, data) {
        console.log(data)
        RoomieRepository.reviewFlaggedHousing(data)
        .then(() => {
            context.dispatch(StoreActions.getFlagged);
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    clearState(context) {
        context.commit(StoreMutations.SET_LISTINGS,[]);
    }
};
