<template>
  <div class="bg-gray" style="height: 100vh; display: flex; flex-direction: column;">
    <!-- Scrollable content container -->
    <div class="container-fluid content-container" v-if="listing">
      <div class="user-card">
        <div class="row">
          <div class="col-sm-12">
            <img
                class="user-img"
                v-for="photo in listing.photos"
                :key="photo.id"
                height="300px"
                :src="photo.assetUrl"
            />
          </div>
          <div class="col-sm-12">
            <a :href="`https://admin.ample.software/#/roomie/user/${listing.userId}`">
              <p>User ID: {{ listing.userId }}</p>
            </a>
          </div>
          <div class="col-sm-4" v-for="prop in properties" :key="prop.value">
            <label>{{ prop.name }}</label>
            <p>{{ listing[prop.value] }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Fixed bottom bar -->
    <div class="navbar navbar-default navbar-fixed-bottom custom-footer">
      <div class="container">
        <div class="row">
          <div class="col-sm-4">
            <label>Sentiment</label>
            <select class="form-control" v-model="sentiment">
              <option value="Scam">❌ Scam</option>
              <option value="Safe">✅ Safe</option>
            </select>
          </div>
          <div class="col-sm-4">
            <label>Approved</label>
            <select class="form-control" v-model="approved">
              <option value="1">Approved</option>
              <option value="0">Remove</option>
            </select>
          </div>
          <div class="col-sm-4 text-center">
            <button class="btn btn-primary btn-block" @click="markReviewed()">Mark Reviewed</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { StoreActions } from "./store/actions";
import StoreIndex from "./store/_StoreIndex";
import {StoreState as state} from './store/state'

export default {
  name: "Listing",
  components: {
  },
  data() {
    return {
      suspendConfirm: false,
      sentiment: "Scam",
      approved: "1"
    };
  },
  mounted() {
    this.dispatch(StoreActions.getFlagged, this.$route.params.id);
  },
  methods: {
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    },
    markReviewed() {
      const isApproved = this.approved === "1"
      this.dispatch(StoreActions.review, {
        id: this.listing.id,
        approved: isApproved,
        sentiment: this.sentiment
      })
    }
  },
  computed: {
    ...mapState(StoreIndex.path,[
      state.listing
    ]),
    properties() {
        return [
            {name:"Name",value:"name"},
            {name:"Price",value:"price"},
            {name:"Address",value:"address"},
            {name:"City",value:"city"},
            {name:"State",value:"state"},
            {name:"Zip",value:"zip"},
            {name:"Lat State",value:"lat"},
            {name:"Lng",value:"lng"},
            {name:"Hide Address",value:"hideAddress"},
            {name:"Type",value:"type"},
            {name:"Approved",value:"approved"},
            {name:"Boost",value:"boost"},
            {name:"Timestamp",value:"ts"},
            {name:"Deposit",value:"deposit"},
            {name:"Location Confirmed",value:"locationConfirmed"},
            {name:"Pets Allowed",value:"petsAllowed"},
            {name:"Smoking Allowed",value:"smokingAllowed"},
            {name:"Business",value:"business"},
            {name:"PO Box",value:"poBox"},
            {name:"Premise",value:"premise"},
            {name:"LGBT Friendly",value:"lgbtFriendly"},
            {name:"Num Rooms",value:"numRooms"},
            {name:"Num Bathrooms",value:"numBathrooms"},
            {name:"Sq. Feet",value:"squareFeet"},
            {name:"Suspended",value:"isSuspended"},
            {name:"Desc",value:"desc"}
        ]

    },
    watch: {
      listing(newVal) {
        this.sentiment = newVal.sentiment
        this.approved = newVal.approved ? "1" : "0"
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background: #46c3f0;
  color: white;
}
.btn-primary {
  background: #46c3f0 !important;
  border-color: #46c3f0 !important;
}

a {
  color: #46c3f0;
}

.btn-secondary {
  background: lightgray;
  border-color: lightgray;
}

@media (max-width: 700px) {
  .mobile-hide {
    display: none;
  }
}

@media (min-width: 701px) {
  .large-hide {
    display: none;
  }
}

.user-card {
  background: white;
  margin: 20px;
  border-radius: 10px;
}

.user-img {
  border-radius: 10px;
  margin: 10px;
}

label {
  color: gray;
  font-weight: bold;
}
</style>

<style scoped>
/* Ensure the content area doesn't overlap with the fixed navbar */
.content-container {
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: 70px; /* Adjusted to avoid content cutoff */
}

/* Prevent the fixed footer from overlapping */
.custom-footer {
  background: white;
  padding: 10px;
  border-top: 1px solid #ddd;
  height: 80px; /* Footer height should match the padding-bottom of content */
}

.btn-block {
  margin-top: 25px;
}
</style>